.preloader {
	@extend %center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #010101;
	z-index: 99;
	transition: opacity 1s ease;
	opacity: 1;
}

.preloader__icon {
	max-width: 64px;
	animation: preloading 2s infinite ease;
}

.preloader__icon__image {
	max-width: inherit;
}

@keyframes preloading {
	0% {
		opacity: 0;
	}
	51% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
