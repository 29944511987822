*,
*:after,
*:before {
	box-sizing: border-box;
}
:root {
	--data-color-primary: #{$color-black};
	--data-color-secondary: #{$color-white};
}
html {
	@extend %cover;

	font-family: $font-inter;
	font-size: 100%;
	line-height: 1;

	color: rgb(var(--data-color-primary));
	background: #010101;
	// overflow: hidden;

	overscroll-behavior-x: none;

	cursor: default;

	@include media('<phone') {
		font-size: 80%;
	}
}

figcaption {
	display: none;
}

body {
	overscroll-behavior-x: none;
	background: #010101;
}

a {
	text-decoration: none;
	color: inherit;
	outline: none;
}

button {
	background: none;
	border: none;
	color: inherit;
	cursor: pointer;
	outline: none;
}

img {
	pointer-events: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.webgl {
	position: fixed;
	top: 0;
	left: 0;
	outline: none;
}
