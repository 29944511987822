nav {
	position: absolute;
	z-index: 2;
	width: inherit;
	height: inherit;
	transition-property: background, backdrop-filter;
	transition-duration: 1s;
	transition-timing-function: ease;

	background: rgba(0, 0, 0, 0);
	backdrop-filter: blur(0px);
}

nav.open {
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(11px);
}

.menu {
	position: fixed;
	visibility: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}
.menu .open {
	visibility: visible;
}

.menu__icon {
	position: fixed;
	top: 3rem;
	right: 3rem;
	width: 30px;
	height: 20px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 99;
}

.menu__icon__line {
	width: 100%;
	height: 3px;
	background-color: #fff;
	transition: all 0.3s ease;
	border-radius: 2px;
}

.menu__contents {
	@extend %center;
	height: inherit;
	width: inherit;

	@include media('<phone') {
		flex-direction: column;
	}
}

.menu__contents__left {
	@extend %center;
	flex-direction: column;
	height: inherit;
	width: inherit;

	@include media('<phone') {
		height: 75%;
	}
}
.menu__contents__right {
	@extend %center;
	flex-direction: column;
	height: inherit;
	width: inherit;

	@include media('<phone') {
		justify-content: flex-start;
	}
}

.social__media {
	@extend %center;

	visibility: hidden;
	opacity: 0;
	transition: all 1s ease;
	color: #fff;

	@include media('<phone') {
		margin-top: 64px;
	}
}

.social__media__link {
	@extend %description-18;
	@extend %link--hidden;

	height: 2rem;
	margin: 0 2rem;
}

.social__media__link:after {
	bottom: -0.05rem;
}

.social__logo {
	margin: 4rem 0;

	@extend %hidden;
	transition: all 1s ease;

	@include media('<phone') {
		margin: 20px 0;
	}
}
.social__logo__img {
	max-height: 15rem;
	width: auto;

	@include media('<phone') {
		max-height: 96px;
	}
}
.social__logo figcaption {
	display: none;
}

// Right Section
.news__image__container {
	@extend %hidden;
	border-radius: 10px;
	max-width: 500px;
	height: auto;

	transition-property: opacity, visibility;
	transition-duration: 1s;
	transition-timing-function: ease;

	@include media('<phone') {
		max-width: 192px;
	}
}

.news__image__container figcaption {
	display: none;
}
.news__image {
	max-width: inherit;
	height: inherit;
	border-radius: inherit;
}

.news__title {
	@extend %description-18;
	text-transform: uppercase;
	font-weight: 900;
	color: white;
	margin-top: 0.5rem;
	margin-bottom: 2rem;

	@extend %hidden;
	transition: all 1s ease;

	@include media('<phone') {
		max-width: 328px;
		margin-top: 15px;
		margin-bottom: 20px;
	}
}
.news__button {
	@extend %center;
	@extend %description-14;
	text-transform: uppercase;
	font-weight: 900;

	max-height: 5rem;
	max-width: 12rem;
	height: 100%;
	width: 100%;

	background: white;
	border-radius: 4px;
	color: #010101;

	@extend %hidden;
	transition-property: opacity, visibility;
	transition-duration: 1s;
	transition-timing-function: ease;
}

.news__button:hover {
	background: #19705f;
	color: #fff;
}

// Open Menu

.open .menu__icon__line--1 {
	transform: translateY(8.5px) rotate(45deg);
}
.open .menu__icon__line--2 {
	transform: translateY(-8.5px) rotate(-45deg);
}
.open .social__logo {
	visibility: visible;
	opacity: 1;
}
.open .social__media {
	visibility: visible;
	opacity: 1;
	transition-delay: 0.1s;
}
.open .news__image__container {
	visibility: visible;
	opacity: 1;
	transition-delay: 0.3s;
}
.open .news__title {
	visibility: visible;
	opacity: 1;
	transition-delay: 0.5s;
}
.open .news__button {
	visibility: visible;
	opacity: 1;
	transition-delay: 0.5s;
}
