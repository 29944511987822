.grain__min {
	height: 100%;
	left: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 0;
}
.grain__min:after {
	animation: grain 8s steps(10) infinite;
	background-image: url(images/noise.jpg);
	content: '';
	height: 300%;
	left: -50%;
	opacity: 0.04;
	position: fixed;
	top: -100%;
	width: 300%;
}

@keyframes grain {
	0%,
	100% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(-5%, -10%);
	}
	20% {
		transform: translate(-15%, 5%);
	}
	30% {
		transform: translate(7%, -25%);
	}
	40% {
		transform: translate(-5%, 25%);
	}
	50% {
		transform: translate(-15%, 10%);
	}
	60% {
		transform: translate(15%, 0%);
	}
	70% {
		transform: translate(0%, 15%);
	}
	80% {
		transform: translate(3%, 5%);
	}
	90% {
		transform: translate(-10%, 10%);
	}
}

.grain {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background-image: url(../../shared/images/grain.webm);
	opacity: 0.05;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	z-index: 150;
	pointer-events: none;
}
