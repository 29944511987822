body {
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overflow: hidden;
}

main {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative;
	z-index: 1;
	width: inherit;
	height: inherit;
}

h1 {
	font-size: 1.25rem;
	color: white;
	font-weight: 900;
	text-transform: uppercase;
	padding: 1rem;
}

.site__by {
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 1rem;
	color: #fff;
	z-index: 5;

	@extend %hidden;
	transition: all 1s ease;
}

.open .site__by {
	opacity: 0.7;
	visibility: visible;
	transition-delay: 0.5s;
}

.model-by {
	@extend %hidden;
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 1rem;
	color: #fff;
	z-index: 5;

	transition: all 1s ease;
}

.model-by a {
	@extend %link--hidden;
	height: 20px;

	opacity: 0.7;
}

.model-by a:after {
	bottom: 0px;
}
.model-by a:hover {
	opacity: 1;
}

.open .model-by {
	opacity: 0.7;
	visibility: visible;
	transition-delay: 0.5s;
}
